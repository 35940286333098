<template>
  <div>
    <b-modal v-model="showModal" @hidden="hideModal" title="Address" size="xl">
      <div v-if="this.transactionAddress">
        <iframe
          width="100%"
          height="170"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          :src="`https://maps.google.com/maps?q=${this.transactionAddress.latLongAsGeometry.latitude},${this.transactionAddress.latLongAsGeometry.longitude}&hl=es&z=14&amp;output=embed`"
        >
        </iframe>
        <SectionAddress :data="transactionAddress" />
      </div>
      <div class="text-center text-black my-2" v-else>
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Loading...</strong>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SectionAddress from "./SectionAddress.vue";
export default {
  name: "ModalAddress",
  components: {
    SectionAddress,
  },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    transactionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      transactionAddress: null,
      showModal: false,
    };
  },
  watch: {
    isShowModal(val) {
      this.showModal = val;
      if (this.showModal === true) {
        this.getTransactionAddress();
      }
    },
  },

  methods: {
    async getTransactionAddress() {
      const respone = await this.$services.transaction.getTransactionAddress(
        this.transactionId
      );

      if (respone.result === 1) {
        this.transactionAddress = respone.detail;
      }
    },
    hideModal() {
      this.transactionAddress = null;
      this.showModal = false;
      this.$emit("onClickCloseModalAddress");
    },
  },
};
</script>

<style lang="scss" scoped></style>
