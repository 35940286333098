<template>
  <div class="transaction">
    <b-row>
      <b-col cols="4">
        <p class="text-header">Transaction</p>
      </b-col>
      <b-col cols="8">
        <div class="tw-flex tw-w-full">
          <div class="g-form">
            <div class="input">
              <b-form-input
                v-model="filter.keyword"
                placeholder="Transaction"
                @focus="iconSearch = true"
                @blur="iconSearch = false"
                @keyup.enter="getTransaction"
              ></b-form-input>
              <b-button
                class="search"
                :class="iconSearch ? 'active-border' : ''"
                @click="getTransaction"
              >
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-button>
            </div>
          </div>

          <b-button class="btn-filter ml-2" @click="openSideBar">
            <span class="d-none d-md-block">
              <font-awesome-icon icon="filter" class="pointer" />
              <span class="btn-text">Filter</span>
            </span>
          </b-button>

          <b-dropdown text="Export" right class="ml-2">
            <b-dropdown-item
              v-for="(item, index) in optionExport"
              :key="index"
              @click="onClickExportTransaction(item)"
            >
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>

          <b-button
            class="btn-filter ml-2"
            @click="handleClickCreateTransaction"
            v-if="visibleBtnCreate"
          >
            <span class="d-none d-md-block">
              <span class="">Create</span>
            </span>
          </b-button>
          <b-button
            class="btn-filter ml-2 tw-w-2/4"
            @click="handleClickRefund"
            :disabled="isLoadingRefund"
            v-if="selectedRefund.length > 0"
          >
            <div class="d-none d-md-block" v-if="isLoadingRefund">
              <b-spinner class="align-middle"></b-spinner>
            </div>
            <span class="d-none d-md-block" v-else>
              <span class="">Complete Refund</span>
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <SideBarFilter
      :filter="filter"
      ref="sidebarFilter"
      placeholder="Transaction"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="my-3">
          <InputText
            v-model="filter.keyword"
            textFloat="Search"
            placeholder="Transaction"
            type="text"
            name="text"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Status --</b-form-select-option
              >
              <b-form-select-option :value="null">All </b-form-select-option>
            </template></InputText
          >
        </div>
        <!-- <div class="mt-3 mb-3" v-if="$isEventGroup !== true">
          <InputSelect
            v-model="configFilter.statusId"
            :options="optionsStatus"
            title="สถานะ"
            class="mt-2"
            valueField="transactionStatusId"
            textField="name"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Status --</b-form-select-option
              >
              <b-form-select-option :value="null">All </b-form-select-option>
            </template></InputSelect
          >
        </div> -->
        <div class="mt-3 mb-3">
          <InputSelect
            v-model="configFilter.customerStatusFlowId"
            :options="optionsCustomerFlowStatus"
            title="สถานะ"
            class="mt-2"
            valueField="id"
            textField="name"
          />
        </div>
        <div class="mt-3 mb-3">
          <InputSelect
            v-model="configFilter.paymentTypeId"
            :options="optionsPayment"
            title="ช่องทางการโอนเงิน"
            class="mt-2"
            valueField="paymentTypeId"
            textField="name"
          />
        </div>
        <div class="d-flex">
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่สร้างเริ่มต้น</div>
            <div :class="['input-container']">
              <datetime
                v-model="configFilter.createdTime.startTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="createStartDate"
                :max-datetime="maxDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.createStartDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mx-1"></div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่สร้างสิ้นสุด</div>
            <div :class="['input-container']">
              <datetime
                v-model="configFilter.createdTime.endTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="createEndDate"
                :disabled="!configFilter.createdTime.startTime"
                :min-datetime="minDateCreate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.createEndDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p
              class="text-error"
              v-if="$v.configFilter.createdTime.endTime.$error"
            >
              กรุณาเลือก
            </p>
          </div>
        </div>

        <div class="d-flex" v-if="$isEventGroup !== true">
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่ชำระเงินเริ่มต้น</div>
            <div :class="['input-container']">
              <datetime
                v-model="configFilter.paymentTime.startTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="paymentStart"
                :max-datetime="maxDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.paymentStart.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mx-1"></div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">วันที่ชำระเงินสิ้นสุด</div>
            <div :class="['input-container']">
              <datetime
                v-model="configFilter.paymentTime.endTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="paymentEndDate"
                :disabled="!configFilter.paymentTime.startTime"
                :max-datetime="maxDate"
                :min-datetime="minDatePayment"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.paymentEndDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p
              class="text-error"
              v-if="$v.configFilter.paymentTime.endTime.$error"
            >
              กรุณาเลือก
            </p>
          </div>
        </div>
        <div class="flex">
          <div class="mb-3">
            <div class="font-weight-bold mb-2">รอบวันที่ + เวลาเริ่มต้น</div>
            <div :class="['input-container']">
              <datetime
                type="datetime"
                v-model="configFilter.bookingDate.startTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy HH:mm:ss"
                value-zone="Asia/Bangkok"
                placeholder="Please Select Date"
                ref="bookingDateStart"
                :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                use24-hour
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.bookingDateStart.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mx-1"></div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">รอบวันที่ + เวลาสิ้นสุด</div>
            <div :class="['input-container']">
              <datetime
                type="datetime"
                v-model="configFilter.bookingDate.endTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy HH:mm:ss"
                value-zone="Asia/Bangkok"
                placeholder="Please Select Date"
                ref="bookingDateEndDate"
                :disabled="!configFilter.bookingDate.startTime"
                :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                use24-hour
                :min-datetime="minDateBooking"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.bookingDateEndDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p
              class="text-error"
              v-if="$v.configFilter.bookingDate.endTime.$error"
            >
              กรุณาเลือก
            </p>
          </div>
        </div>
        <div>
          <div v-if="$isEventGroup !== true">
            <AutocompleteCustom
              v-model="configFilter.eventId"
              ref="AutocompleteCustom"
              id="eventId"
              label="Event"
              placeholder="Event"
              dataListID="dataListID"
              valueField="eventId"
              showValue=""
              :optionsList="optionsEvent"
              @handleInput="handleInput"
            />
          </div>
          <div v-else>
            <div class="mt-2">
              <AutocompleteCustom
                v-model="configFilter.eventGroupId"
                ref="AutocompleteCustomEventGroup"
                id="serviceId"
                label="Service"
                placeholder="Service"
                dataListID="dataListID"
                valueField="id"
                showValue=""
                :optionsList="optionEventGroup"
                @handleInput="handleInput"
              />
            </div>
            <div class="mt-2">
              <AutocompleteCustom
                v-model="configFilter.brandId"
                ref="AutocompleteCustomBrand"
                id="brandId"
                label="Brand"
                placeholder="Brand"
                dataListID="dataListID"
                valueField="id"
                showValue=""
                :optionsList="optionBrand"
                @handleInput="handleInputBrand"
              />
            </div>
            <div class="mt-2">
              <AutocompleteCustom
                v-model="configFilter.venueId"
                ref="AutocompleteCustomVenue"
                id="venueId"
                label="Branch"
                placeholder="Branch"
                dataListID="dataListID"
                valueField="id"
                showValue=""
                :optionsList="optionVenue"
                @handleInput="handleInputVenue"
              />
            </div>
          </div>
        </div>
      </template>
    </SideBarFilter>

    <Table
      :items="items"
      :fields="fields"
      :isBusy="isBusy"
      :rows="rows"
      :filter="filter"
      :pageOptions="pageOptions"
      @filterPage="filterPage"
      @getPaymentInfo="getPaymentInfo"
    />

    <ModalConfirmPayment
      :dataPayment="dataPayment"
      @confirmSuccess="confirmSuccess"
    />
  </div>
</template>

<script>
import Table from "./component/Table.vue";
import ModalConfirmPayment from "@/components/modal/ModalConfirmPayment.vue";
import AutocompleteCustom from "@/components/inputs/AutocompleteCustom";
import * as moment from "moment/moment";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    Table,
    ModalConfirmPayment,
    AutocompleteCustom,
  },
  data() {
    return {
      // state
      filter: {
        filter: null,
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      },

      configFilter: {
        customerStatusFlowId: null,
        statusId: null,
        paymentTypeId: null,
        eventId: "",
        eventGroupId: "",
        brandId: "",
        venueId: "",
        createdTime: {
          startTime: null,
          endTime: null,
        },
        paymentTime: {
          startTime: null,
          endTime: null,
        },
        bookingDate: {
          startTime: null,
          endTime: null,
        },
      },

      // option export
      optionExport: [
        {
          id: 1,
          name: "Order",
          enpoint: "/Transaction/Export",
          fileName: "TransactionExport",
        },
        // {
        //   id: 2,
        //   name: "Product",
        //   enpoint: "/Transaction/ExportTransactionProduct",
        //   fileName: "TransactionProductExport",
        // },
      ],

      // options filter
      optionsStatus: [],
      optionsCustomerFlowStatus: [],
      optionsPayment: [],
      optionsEvent: [],
      optionEventGroup: [],
      optionBrand: [],
      optionVenue: [],

      // condition date
      maxDate: null,

      minDateCreate: null,
      minDatePayment: null,
      minDateBooking: null,

      // state modal
      dataPayment: null,

      // state table
      items: [],

      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },

      // state css
      iconSearch: false,

      // state delay
      isSearchEvent: null,

      levelCreateTransaction: 14,

      isLoadingRefund: false,
    };
  },
  validations() {
    return {
      configFilter: {
        createdTime: {
          endTime: {
            required: requiredIf(() => {
              return this.configFilter.createdTime.startTime;
            }),
          },
        },
        paymentTime: {
          endTime: {
            required: requiredIf(() => {
              return this.configFilter.paymentTime.startTime;
            }),
          },
        },
        bookingDate: {
          endTime: {
            required: requiredIf(() => {
              return this.configFilter.bookingDate.startTime;
            }),
          },
        },
      },
    };
  },
  watch: {
    "configFilter.createdTime.startTime"(val) {
      if (val) {
        this.minDateCreate = new Date(val).toISOString();

        // clear endtime
        this.configFilter.createdTime.endTime = null;
      }
    },
    "configFilter.paymentTime.startTime"(val) {
      if (val) {
        this.minDatePayment = new Date(val).toISOString();

        // clear endtime
        this.configFilter.paymentTime.endTime = null;
      }
    },
    "configFilter.bookingDate.startTime"(val) {
      if (val) {
        this.minDateBooking = new Date(val).toISOString();

        // clear endtime
        this.configFilter.bookingDate.endTime = null;
      }
    },
  },
  mounted() {
    this.getTransaction();

    // data sidebar
    this.getStatusOption();
    this.getPaymentOption();

    // condition date
    this.maxDate = new Date().toISOString();
  },
  computed: {
    selectedRefund() {
      return this.items.filter((module) => module.selectedRefund);
    },
    fields() {
      let fields = [
        {
          key: "transactionId",
          label: "Transaction Id",
          class: "w-100px text-nowrap",
        },
        {
          key: "invoiceNo",
          label: "Booking No.",
          class: "w-100px text-nowrap",
        },

        {
          key: "bookingDate",
          label: "รอบ วันที่ + เวลา",
          class: "w-150 text-nowrap",
        },
        {
          key: "paymentTypeName",
          label: "ช่องทางการชำระเงิน",
          class: "w-100px text-nowrap",
        },
        {
          key: "statusName",
          label: "สถานะ",
          class: "min-w-170 text-nowrap",
        },
        {
          key: "promocodeSerialNo",
          label: "Promocode",
          class: "w-100px text-nowrap",
        },
        {
          key: "venueName",
          label: "Branch",
          class: "w-150 text-nowrap",
        },

        {
          key: "grandTotal",
          label: "Grand Total",
          class: "w-50px text-nowrap",
        },
        {
          key: "fullName",
          label: "ชื่อ นามสกุล",
          class: "w-100px text-nowrap",
        },
        {
          key: "email",
          label: "อีเมล",
          class: "w-150 text-nowrap",
        },
        {
          key: "telephone",
          label: "เบอร์โทร",
          class: "w-100px text-nowrap",
        },
        {
          key: "note",
          label: "Note",
          class: "w-150 text-nowrap",
        },
        {
          key: "createdTime",
          label: "วันที่สร้าง",
          class: "w-150 text-nowrap",
        },

        { key: "actions", label: "Action" },
      ];
      if (this.$isEventGroup !== true) {
        const paymentTimeField = {
          key: "paymentTime",
          label: "วันที่ชำระเงิน",
          class: "w-150 text-nowrap",
        };
        const actionsIndex = fields.findIndex(
          (field) => field.key === "actions"
        );
        fields.splice(actionsIndex, 0, paymentTimeField);
        return fields;
      } else {
        return fields;
      }
    },
    visibleBtnCreate() {
      return !!this.$store.state.permission.Permission.find(
        (e) => e.permissionMenuId === this.levelCreateTransaction
      );
    },
  },
  methods: {
    async onClickExportTransaction(v) {
      const getData =
        await this.$services.transaction.exportTransactionByFilter(
          v.enpoint,
          this.filter
        );
      this.resolveAndDownloadBlob(getData, `${v.fileName}`);
    },
    async getTransaction() {
      this.isBusy = true;
      // if (this.filter.keyword) {
      //   this.filter = {
      //     ...this.filter,
      //     page: 1,
      //     skip: 1,
      //   };
      // }
      const params = {
        ...this.filter,
        isEventGroup: this.$isEventGroup,
      };
      const getData = await this.$services.transaction.getTransactionList(
        params
      );

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;

        // const checkData =
        //   (getData.detail.take * getData.detail.skip) / getData.detail.count;

        // if (checkData >= 2) {
        //   this.filter = {
        //     ...this.filter,
        //     page: 1,
        //     skip: 1,
        //   };
        //   this.getTransaction();
        // }
      } else {
        this.$cookies.remove("back_office_admin_sale_order_token");
        this.$router.replace("/");
      }

      this.isBusy = false;
    },
    async getPaymentInfo(refId) {
      const getData = await this.$services.transaction.getPaymentInfo(refId);

      if (getData.result == 1) {
        // add state base64

        getData.detail.bankTransferList = getData.detail.bankTransferList.map(
          (x) => {
            // change format dateonly

            x.transactionDetail.dateOnly = x.transactionDetail.dateOnly
              .split("-")
              .reverse()
              .join("-");

            // add new key to obj

            let obj = {
              ...x,
              base64: {
                base64: null,
              },
              imageName: x.transactionDetail.slipImgUrl.split("/").reverse()[0],
            };

            // conver time

            x.transactionDetail.timeOnly = this.convertTime(
              x.transactionDetail.timeOnly
            );

            return obj;
          }
        );

        getData.detail.refId = refId;

        this.dataPayment = getData.detail;

        this.$bvModal.show("modal-confirm-payment");
      }
    },
    async getStatusOption() {
      const customerStatusFlowData =
        await this.$services.master.getOptionCustomerStatusFlow();
      if (customerStatusFlowData.result === 1) {
        this.optionsCustomerFlowStatus = [
          {
            name: "All",
            id: null,
          },
          ...customerStatusFlowData.detail,
        ];
      }
    },
    async getPaymentOption() {
      const getData = await this.$services.master.getPaymentOption();

      if (getData.result == 1) {
        this.optionsPayment = [
          {
            name: "All",
            transactionStatusId: null,
          },
          ...getData.detail,
        ];
      }
    },
    async getOptionBrand(keyword) {
      const brandData = await this.$services.master.getOptionBrand(keyword);
      if (brandData.result === 1) {
        this.optionBrand = brandData.detail;
      }
    },
    async getOptionVenue(keyword) {
      const venueData = await this.$services.master.getOptionVenue(keyword);
      if (venueData.result === 1) {
        this.optionVenue = venueData.detail;
      }
    },
    async handleClickRefund() {
      this.isLoadingRefund = true;
      if (this.selectedRefund.length > 0) {
        const payload = {
          invoiceNo: this.selectedRefund.map((item) => item.invoiceNo),
          customerStatusId: 2,
        };

        var respone = await this.$services.transaction.changeStatus(payload);
        this.isLoadingRefund = false;
        this.getTransaction();
      }
    },
    async getEventOption(keyword) {
      // if (!keyword) {
      //   return;
      // }

      const check = Number(keyword) ? true : false;

      if (check) {
        return;
      }

      if (this.$isEventGroup === false) {
        const getData = await this.$services.master.getEventOption(keyword);

        if (getData.result == 1) {
          this.optionsEvent = getData.detail;
        }
      } else {
        const eventGroupData = await this.$services.master.getOptionEventGroup(
          keyword
        );
        if (eventGroupData.result === 1) {
          this.optionEventGroup = eventGroupData.detail;
        }
      }
    },
    handleClickCreateTransaction() {
      this.$router.push("/transaction/create");
    },
    handleInput(val) {
      if (this.isSearchEvent) {
        clearTimeout(this.isSearchEvent);
      }

      this.isSearchEvent = setTimeout(() => {
        this.getEventOption(val);
      }, 1000);
    },
    handleInputBrand(val) {
      if (this.isSearchEvent) {
        clearTimeout(this.isSearchEvent);
      }

      this.isSearchEvent = setTimeout(() => {
        this.getOptionBrand(val);
      }, 1000);
    },
    handleInputVenue(val) {
      if (this.isSearchEvent) {
        clearTimeout(this.isSearchEvent);
      }

      this.isSearchEvent = setTimeout(() => {
        this.getOptionVenue(val);
      }, 1000);
    },
    confirmSuccess() {
      this.getTransaction();
    },
    filterPage(obj) {
      this.filter.skip = obj.page;
      this.getTransaction();
    },
    openSideBar() {
      this.$refs.sidebarFilter.show();
      this.getEventOption();
      if (this.$isEventGroup === true) {
        this.getOptionBrand();
        this.getOptionVenue();
      }
    },
    searchAll(filter) {
      let {
        customerStatusFlowId,
        statusId,
        paymentTypeId,
        eventId,
        createdTime,
        paymentTime,
        bookingDate,
        eventGroupId,
        brandId,
        venueId,
      } = this.configFilter;

      let { keyword } = filter;

      let finalFilter = {
        filter: null,
        keyword: keyword ? keyword : "",
        page: 1,
        skip: 1,
        take: 10,
      };

      // validate filter

      this.$v.configFilter.$touch();

      if (this.$v.configFilter.$error) {
        return;
      }

      this.$refs.sidebarFilter.hide();

      // add value to filter

      let newConfigFilter = {};

      statusId && (newConfigFilter.statusId = statusId);
      paymentTypeId && (newConfigFilter.paymentTypeId = paymentTypeId);
      eventId && (newConfigFilter.eventId = Number(eventId));
      brandId && (newConfigFilter.brandId = Number(brandId));
      venueId && (newConfigFilter.venueId = Number(venueId));
      eventGroupId && (newConfigFilter.eventGroupId = Number(eventGroupId));
      customerStatusFlowId &&
        (newConfigFilter.customerStatusFlow = Number(customerStatusFlowId));

      if (createdTime.startTime && createdTime.endTime) {
        newConfigFilter.createdTime = {
          startTime: null,
          endTime: null,
        };

        newConfigFilter.createdTime.startTime = createdTime.startTime;
        newConfigFilter.createdTime.endTime = createdTime.endTime;
      }

      if (paymentTime.startTime && paymentTime.endTime) {
        newConfigFilter.paymentTime = {
          startTime: null,
          endTime: null,
        };

        newConfigFilter.paymentTime.startTime = paymentTime.startTime;
        newConfigFilter.paymentTime.endTime = paymentTime.endTime;
      }

      if (bookingDate.startTime && bookingDate.endTime) {
        newConfigFilter.bookingDate = {
          startTime: null,
          endTime: null,
        };

        newConfigFilter.bookingDate.startTime = bookingDate.startTime;
        newConfigFilter.bookingDate.endTime = bookingDate.endTime;
      }

      newConfigFilter.keyword = this.filter.keyword;

      const isObjEmpty = Object.keys(newConfigFilter).length;

      if (isObjEmpty > 0) {
        finalFilter.filter = newConfigFilter;
      }

      this.filter = finalFilter;

      this.getTransaction();
    },

    convertTime(time) {
      let getTime = `${this.$moment().format("YYYY-MM-DDT")}${time}`;

      return getTime;
    },

    convertTimeHHMM(time) {
      return this.$moment(time).format("HH:mm");
    },

    clearFilter() {
      // clear validate
      this.$v.configFilter.$reset();

      this.filter = {
        filter: null,
        keyword: "",
        skip: 1,
        page: 1,
        take: 10,
      };

      this.configFilter = {
        statusId: null,
        paymentTypeId: null,
        customerStatusFlowId: null,
        eventId: "",
        brandId: "",
        venueId: "",
        eventGroupId: "",
        createdTime: {
          startTime: null,
          endTime: null,
        },
        paymentTime: {
          startTime: null,
          endTime: null,
        },
        bookingDate: {
          startTime: null,
          endTime: null,
        },
      };

      this.clearInputs();
      this.getTransaction();
      this.$refs.sidebarFilter.hide();
    },
    resolveAndDownloadBlob(v, filename) {
      return this.$services.utils.resolveAndDownloadBlob(v, filename);
    },
    clearInputs() {
      if (this.$isEventGroup === true) {
        this.$refs.AutocompleteCustomEventGroup.clearInput();
        this.$refs.AutocompleteCustomVenue.clearInput();
        this.$refs.AutocompleteCustomBrand.clearInput();
      } else {
        this.$refs.AutocompleteCustom.clearInput();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction {
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
}
.text-error {
  margin: 0;
  color: red;
}
// ! override
.g-form {
  width: 100%;
}

::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
  min-width: 80px;
}

.year-month-wrapper {
  background-color: var(--primary-color) !important;
}
</style>
