<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(transactionId)="{ item }">
            <div
              class="tw-flex tw-gap-1 tw-w-full tw-justify-center tw-items-center tw-cursor-pointer"
            >
              <b-form-checkbox
                v-if="item.statusId === 7"
                v-model="item.selectedRefund"
              />
              <p
                class="m-0"
                :class="item.transactionId ? 'underline' : ''"
                @click="goTransactionDetail(item.refId)"
              >
                {{ item.transactionId || "-" }}
              </p>

              <b-button
                class="btn-copy"
                type="button"
                v-clipboard:copy="getTransactionUrl(item.refId)"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                ><font-awesome-icon icon="copy"
              /></b-button>
            </div>
          </template>

          <template #cell(invoiceNo)="{ item }">
            <p class="m-0">
              {{ item.invoiceNo || "-" }}
            </p>
          </template>

          <template #cell(bookingDate)="{ item }">
            <p class="m-0">
              {{
                $moment(item.bookingDate).locale("th").format("Do MMMM YYYY")
              }}
              ,
              {{ item.startTimeShow }}
            </p>
          </template>

          <template #cell(paymentTypeName)="{ item }">
            <div class="render-payment">
              <p class="m-0">
                {{ item.paymentTypeName || "-" }}
              </p>
              <div
                class="d-flex justify-content-center"
                v-if="item.showBtnFlag"
              >
                <b-button
                  variant="link"
                  class="btn main-color px-1 py-0 btn-link"
                  @click="openModalConfirm(item)"
                >
                  <b-icon icon="wallet2"></b-icon>
                </b-button>
              </div>
            </div>
          </template>

          <template #cell(statusName)="{ item }">
            <div class="tw-flex tw-justify-center">
              <p
                class="m-0 tw-rounded-full tw-text-white tw-px-2 status-badge"
                :style="`backgroundColor:${item.color}; ${
                  item.outlineColor &&
                  `color:${item.outlineColor}; border:1px solid ${item.outlineColor}`
                } `"
              >
                {{ item.statusName }}
              </p>
            </div>
          </template>

          <template #cell(brand)="{ item }">
            <p class="m-0">
              {{ item.brandName }}
            </p>
          </template>
          <template #cell(venueName)="{ item }">
            <p class="m-0">
              {{ item.venueName }}
              <font-awesome-icon
                class="mr-2 pointer"
                icon="map-marked-alt"
                @click="onClickShowModalAddress(item)"
                v-if="item.hasBilling"
              />
            </p>
          </template>

          <template #cell(grandTotal)="{ item }">
            <p class="m-0">
              {{ formatNumber(item.grandTotal) }}
            </p>
          </template>

          <template #cell(promocodeSerialNo)="{ item }">
            <p class="m-0">
              {{ item.promocodeSerialNo || "-" }}
            </p>
          </template>

          <template #cell(fullName)="{ item }">
            <p class="m-0">
              {{ item.fullName || "-" }}
            </p>
          </template>

          <template #cell(email)="{ item }">
            <p class="m-0">
              {{ item.email || "-" }}
            </p>
          </template>

          <template #cell(telephone)="{ item }">
            <p class="m-0">
              {{ item.telephone || "-" }}
            </p>
          </template>

          <template #cell(note)="{ item }">
            <p class="m-0">
              {{ item.note || "-" }}
            </p>
          </template>

          <template #cell(createdTime)="{ item }">
            <p class="m-0">
              {{
                $moment(item.createdTime).locale("th").format("Do MMMM YYYY")
              }}, {{ $moment(item.createdTime).locale("th").format("HH:mm") }}
            </p>
          </template>

          <template #cell(paymentTime)="{ item }">
            <p class="m-0">
              {{
                item.paymentTime
                  ? $moment(item.paymentTime)
                      .locale("th")
                      .format("Do MMMM YYYY, HH:mm")
                  : "-"
              }}
            </p>
          </template>
          <template #cell(actions)="{ item }">
            <font-awesome-icon
              class="mr-2 pointer"
              icon="pen"
              @click="goTransactionDetail(item.refId)"
            />
          </template>

          <!-- <template v-slot:cell(actions)="{ item }">
            <div class="d-flex justify-content-center">
              <b-button
                variant="link"
                class="btn main-color px-1 py-0 btn-link"
                @click="openModalConfirm(item)"
              >
                <b-icon icon="wallet2"></b-icon>
              </b-button>
            </div>
          </template> -->

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
    <ModalAddress
      :transactionId="transactionId"
      :isShowModal="showModalAddress"
      @onClickCloseModalAddress="onClickCloseModalAddress"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import ModalAddress from "./ModalAddress.vue";
export default {
  components: { Pagination, ModalAddress },
  data() {
    return {
      showModalAddress: false,
      transactionId: 0,
    };
  },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    selectedRefund: {
      required: false,
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getTransactionUrl(refId) {
      return refId && process.env.VUE_APP_CLIENT_URL
        ? `${process.env.VUE_APP_CLIENT_URL}transaction/${refId}`
        : "";
    },
    onCopy: function (e) {
      this.successAlert(`${e.text}`, "You just copied");
    },
    onError: function (e) {
      this.$swal("Failed to copy", `${e.text}`, "error");
    },

    goTransactionDetail(refId) {
      if (!refId) {
        return;
      }

      this.$router.push(`/transaction/details/${refId}`);
    },
    openModalConfirm(obj) {
      let { refId } = obj;

      this.$emit("getPaymentInfo", refId);
    },
    // handleDelete(item) {
    //   this.$emit("handleDelete", item);
    // },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    colorClass(item) {
      if (item.status == "1") return "text-warning";
      else if (item.status == "2") return "text-success";
      else if (item.status == "3") return "text-danger";
      else return "text-secondary";
    },
    chkBirthday(birthday) {
      let birthDate = birthday.search("00:00:00");
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
    onClickShowModalAddress(item) {
      this.showModalAddress = true;
      this.transactionId = item.transactionId;
    },
    onClickCloseModalAddress() {
      this.showModalAddress = false;
      this.transactionId = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}

.render-payment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.btn-copy {
  background-color: unset !important;
  border: none !important;
}
.status-badge {
  width: fit-content;
}
</style>
